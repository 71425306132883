import { Link } from 'gatsby';
import React from 'react';
import Image from '../elements/Image';

const CaseStudyPreview = ({ uri, featuredImage, author, date, title, casestudy }) => {
    console.log(casestudy)
    return (
        <Link to={uri}>
            <div className="relative flex-none rounded aspect-w-382 aspect-h-237 image-absolute ">
                {featuredImage ? (
                    <Image
                        objectFit="cover"
                        image={featuredImage?.node}
                        className="absolute object-cover rounded pointer-events-none "
                    />
                ) : (
                    <div className="flex items-center justify-center w-full h-full rounded bg-blue">
                        <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M0 26.5C0 26.9098 0.268683 27.0464 0.537365 27.0464C14.3772 29.7784 23.1113 37.5644 25.5298 52.4537C25.5298 52.7269 25.7985 53 26.0672 53C26.3359 53 26.4702 52.7269 26.4702 52.4537C28.889 37.5644 37.6228 29.7784 51.4626 27.0464C51.7313 27.0464 52 26.7732 52 26.5C52 26.2268 51.7313 25.9536 51.4626 25.9536C37.6228 23.2217 28.889 15.4356 26.4702 0.546391C26.4702 0.273197 26.2015 0 25.9328 0C25.6642 0 25.5298 0.273197 25.5298 0.546391C23.1113 15.4356 14.3772 23.2217 0.537365 25.9536C0.268683 25.9536 0 26.0902 0 26.5Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                )}
            </div>
            <div className="mt-[30px]">
                <div class="flex items-center">
                    <div class="flex-none">
                        {casestudy?.squareLogo && <Image image={casestudy?.squareLogo} className="w-[60px] h-[60px] object-cover rounded-xl" />}
                    </div>
                    <div class="flex-grow ml-4 border-b-0 h-[60px] border-b-dark-purple border-opacity-50">
                        <h4 className="font-body text-[24px] leading-[1.17] tracking-[-0.01em] ">{casestudy?.company ? casestudy?.company : title}</h4>
                        <div className='text-[14px] opacity-60'>{casestudy?.industry}</div>
                    </div>
                </div>
                {/* <div className="mt-[14px]">
                    {casestudy?.subtitle}
                </div> */}
            </div>
        </Link >
    );
};

export default CaseStudyPreview;
