import React from 'react';
import { WpPage_Page_FlexibleContent_TwoColumn_Section } from '~/_generated/types';

export interface SectionProps extends WpPage_Page_FlexibleContent_TwoColumn_Section {
  layoutName?: string;
  children?: React.ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  previousSection?: SectionProps;
  nextSection?: SectionProps;
  background?: string;
  id?: string;
  sectionClass?: string;
  style?: string;
}

export const Section = (props: SectionProps) => {
  const {
    children,
    isFirst,
    isLast,
    previousSection,
    nextSection,
    layoutName = 'default',
    id,
    background,
    sectionclass,
    style,
  } = props;
  const className = props.class;

  const paddingClasses = getSectionPaddingClasses(background, layoutName, previousSection, nextSection);
  const colourClasses = getSectionColourClasses(background);
  const layoutSpecificClasses = getLayoutSpecificClasses(layoutName);

  return (
    <section
      style={style}
      id={id}
      data-layout={layoutName}
      className={`${layoutSpecificClasses} ${className} ${paddingClasses} ${colourClasses} ${sectionclass} mx-auto `}
    >
      {children}
    </section>
  );
};

// Layout specific classes

function getLayoutSpecificClasses(layoutName: string) {
  let layoutSpecificClasses = '';

  if (layoutName === 'Social') {
    layoutSpecificClasses = '!pb-[9rem] !md:pb-32';
  }

  if (layoutName === 'Testimonials') {
    layoutSpecificClasses = '!pt-0 md:!pt-32 !pb-10 md:!pb-16';
  }

  return layoutSpecificClasses;
}

// Helper function to decide padding amounts on sections

function getSectionPaddingClasses(
  background: string,
  layoutName: string,
  previousSection: SectionProps,
  nextSection: SectionProps
) {
  const previousBackground = previousSection && previousSection.background ? previousSection.background : null;
  const nextBackground = nextSection && nextSection.background ? nextSection.background : null;

  const previousName = previousSection && previousSection.layoutName ? previousSection.layoutName : null;
  const nextName = nextSection && nextSection.layoutName ? nextSection.layoutName : null;

  let topPaddingClass = 'pt-10 md:pt-16';
  let bottomPaddingClass = 'pb-10 md:pb-16';

  // If next or previous item is not of the same background colour add top or bottom padding
  if (previousBackground !== background) {
    topPaddingClass = 'pt-20 md:pt-32';
  }
  if (nextBackground !== background) {
    bottomPaddingClass = 'pb-20 md:pb-32';
  }

  if (!previousBackground) {
    topPaddingClass = '';
  }

  if (layoutName === 'Masthead') {
    bottomPaddingClass = '';
  }

  if (layoutName === 'Plans') {
    topPaddingClass = 'pt-10 md:pt-[13rem]';
  }

  if (previousName === 'Masthead' && layoutName === 'Features') {
    topPaddingClass = 'pt-[150px] md:pt-[10rem]';
  }

  return `${topPaddingClass} ${bottomPaddingClass}`;
}

// Helper function to decide colour classes by background colour

function getSectionColourClasses(background: string) {
  let colourClasses = '';
  switch (background) {
    case 'white':
      colourClasses = 'bg-white';
      break;
    case 'black':
      colourClasses = 'bg-dark-purple text-white';
      break;
    case 'dark-purple':
      colourClasses = 'bg-dark-purple text-white';
      break;
    case 'grey':
      colourClasses = 'bg-grey';
    case 'light-grey':
      colourClasses = 'bg-light-grey';
      break;
  }
  return colourClasses;
}
