import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '~/components/global/Layout';

import { WpPage_Page_FlexibleContent } from '~/_generated/types';
import useBlogPosts from '~/hooks/useBlogPosts';
import { ResourceGridInner } from '../resources/ResourceGrid';
import Pagination from '../global/Pagination';
import { SeoContainer } from '../global/SeoContainer';

export default function categoryPage({
  data: { wpPage, wp, allWpCategory, allWpPost, wpCategory },
  pageContext,
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  return (
    <Layout wp={wp}>
      <SeoContainer
        post={wpPage}
        title={`${wpCategory?.name} Posts - Ablestar`}
      />
      <div className="container pt-[7rem] lg:pt-[16rem] pb-[5rem] md:pb-[9rem]">
        <h1 className="text-center text-h1">All {wpCategory?.name}</h1>
        {wpCategory.description && <p className="text-lg text-black opacity-60 text-center mt-4">{wpCategory.description}</p>}
        <div className="mt-[29px] md:mt-[84px]">
          <ResourceGridInner posts={allWpPost?.nodes} />
          <Pagination {...pageContext} allWpCategory={allWpCategory} />
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query Category($id: String!, $skip: Int!, $limit: Int!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }

    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
      skip: $skip
      limit: $limit
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        date
        dateGmt
        title
        slug
        uri
        author {
          node {
            name
          }
        }
        excerpt
        postFlexibleContent {
          masthead {
            image {
              ...Image
            }
            description
          }
        }
        featuredImage {
          node {
            ...Image
          }
        }
      }
    }
    allWpCategory(
      filter: { posts: { nodes: { elemMatch: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } } } } }
      sort: { fields: posts___nodes___date, order: DESC }
    ) {
      nodes {
        uri
        name

        id
        # categoryPage {
        #   categoryPageSubheading
        #   categoryPageHeading
        #   allCategoriesHeading
        # }
      }
    }
    wpCategory(id: { eq: $id }) {
      ...GeneratedWpCategory
    }
  }
`;
